import { isEmpty } from "lodash";
import React from "react";

//component
import Layout from "../../components/layout";
import Title from "../../components/ui/title";
import { UseUser } from "../../hooks/context/user";
import Rating from "./Rating";

function Home() {
  const { user } = UseUser();
  return (
    <Layout>
      <Title text="Dashboard" />
      {!isEmpty(user.menu) &&
        !isEmpty(user.menu.dashboard) &&
        !isEmpty(user.menu.dashboard.leagues) &&
        user.menu.dashboard.leagues.length > 0 && (
          <Rating permitions={user.menu.dashboard.leagues} />
        )}
    </Layout>
  );
}
export default Home;
