import React, { useEffect, useState } from "react";

//components
import Layout from "../../components/layout";
import Title from "../../components/ui/title";
import Form from "./form";
import Pagination from "../../components/pagination";

//styled
import { StyledModalContainer } from "./styled";
import Card from "../../components/card";
import SectionHeader from "./SectionHeader";
import { getApiFilters, getApiLeads } from "../../actions";
import Container from "./Container";
import { isEmpty } from "lodash";

const Lead = () => {
  const [leads, setLeads] = useState({ leads: [], total: 0 });
  const [filters, setFilters] = useState({
    type: "",
    opportunity_type: "",
    team: "",
    user: "",
    provenance: "",
  });
  const [filtersData, setFiltersData] = useState([]);
  const [message, setMessage] = useState({ open: false, text: "" });
  const [paginate, setPaginate] = useState({
    limit: 25,
    offset: 0,
    current: 1,
  });
  const [openModa, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const getLeads = async (limit, offset) => {
    const { type, opportunity_type, team, user, provenance } = filters;
    setLoading(true);
    const data = await getApiLeads({
      limit,
      offset,
      type,
      opportunity_type,
      team,
      user,
      provenance,
    });

    if (data !== undefined && !isEmpty(data.leads)) {
      setLeads({ leads: data.leads, total: data.total });
    } else {
      setLeads({ leads: [], total: 0 });
    }
    setLoading(false);
  };

  const getFilters = async () => {
    const data = await getApiFilters(filters);
    setFiltersData(data);
  };

  useEffect(() => {
    getLeads(paginate.limit, paginate.offset);
    getFilters();
  }, [filters]);

  const changeCurrent = (current) => {
    setPaginate({ ...paginate, current: current });
  };

  const refresh = () => {
    setPaginate({ ...paginate, current: 1 });
    getLeads(paginate.limit, paginate.offset);
  };

  const modal = () => {
    setOpenModal(!openModa);
  };

  const closeOpenMessage = () => setMessage({ open: false, text: "" });

  const openMessage = (message) => {
    setMessage({ ...message, open: !message.open, text: message });
  };

  return (
    <Layout>
      <Title text="Candidatos" />
      <SectionHeader
        filters={filtersData}
        filtersValue={filters}
        setFilters={setFilters}
        refresh={refresh}
        modal={modal}
      />
      <Container loading={loading} leads={leads} openMessage={openMessage} />
      {leads.total > paginate.limit && (
        <Pagination
          total={leads.total}
          action={getLeads}
          current={paginate.current}
          changeCurrent={changeCurrent}
          limit={paginate.limit}
          offset={paginate.offset}
          text="Candidatos"
        />
      )}
      {message.open && (
        <StyledModalContainer>
          <Card
            title="Mensaje"
            isClosed
            size="20px"
            close={closeOpenMessage}
            margin
            heigth
          >
            <p>{message.text}</p>
          </Card>
        </StyledModalContainer>
      )}
      {openModa && <Form modal={modal} getLeads={getLeads} />}
    </Layout>
  );
};

export default Lead;
