const { default: styled } = require("styled-components")

export const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    @media (max-width: 920px) {
        grid-template-columns: auto;
    }
`

export const StyledInputContent = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &:last-child { margin-bottom: 0 }
    label {
        margin-right: 15px;
        font-size: 15px;
        width: 160px
    }
    &.ag {
        label { width: initial; }
    }
    input,
    select {
        padding: 4px 10px;
        height: 25px;
        width: 100%;
        cursor: text;
        text-align: left;
        font-size: 13px;
        line-height: 1.5;
        color: #595959;
        background-color: #fff;
        background-image: none;
        border: 1px solid #e9e9e9;
        outline: none;
        border-radius: 4px;
        transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }
    select { height: 34px }
`