import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import { useAlert } from "../../hooks/context/alert";
import { pColor, sColor } from "../../styles/variables";

//components
import Title from "../../components/ui/title";
import Loading from "../../components/loading";
import Card from "../../components/card";

//styles
import { StyledContainer, StyledInputContent } from "./styles";
import NotData from "../../components/notData";
import { getApiDispatcher, updateApiDispatcherAdviser } from "../../actions";

function Advisers({ type, typeName }) {
  const [advisers, setAdvisers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { openAlert } = useAlert();

  const getAdvisers = async () => {
    setLoading(true);
    const data = await getApiDispatcher({ type, option: "users" });
    setAdvisers(data);
    setLoading(false);
  };

  const update = async (active, id) => {
    setLoading(true);
    await updateApiDispatcherAdviser({ type, id, active }, openAlert);
    setLoading(false);
  };

  useEffect(() => {
    getAdvisers();
  }, [type]);

  return (
    <>
      <Title text={`Assesores ${typeName} online`} />
      {loading && <Loading />}
      {advisers && advisers.length <= 0 ? (
        <NotData />
      ) : (
        <StyledContainer>
          {advisers.map((item, index) => {
            return (
              <Card key={index} title={item.name} fullWeight>
                {item.users.map((item, index) => {
                  return (
                    <Check
                      key={index}
                      first_name={item.first_name}
                      last_name={item.last_name}
                      active={item.dispatcher_active}
                      update={update}
                      id={item._id}
                    />
                  );
                })}
              </Card>
            );
          })}
        </StyledContainer>
      )}
    </>
  );
}

const Check = ({ first_name, last_name, id, active, update }) => {
  const [act, setAct] = useState(active);
  const handleOnchange = (checked) => {
    setAct(checked);
    update(checked, id);
  };
  return (
    <StyledInputContent>
      <label>
        {first_name} {last_name}
      </label>
      <Switch
        onChange={handleOnchange}
        checked={act}
        onColor={pColor}
        offColor={sColor}
      />
    </StyledInputContent>
  );
};

export default Advisers;
