import styled from 'styled-components';
import { Link } from "react-router-dom";

export const StyledContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url('/static/login.webp') no-repeat center center;
  background-size: cover;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 1;
    top: 0;
    left: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '0')};
    right: ${props => (props['data-rtl'] === 'rtl' ? '0' : 'inherit')};
  }
`

export const StyledContent = styled.div`
  width: 400px;
  height: fit-content;
  overflow-y: auto;
  z-index: 10;
  position: relative;
  .login-media {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;
    position: relative;
    background-color: #ffffff;
  }
  h1 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0; 
    color: #323332;
    &.title-reset {margin: 5px 0px 20px 0px;}
  }
  p {
    margin: 5px 0px 20px 0px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    color: #979797;
  }
`

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
  flex-shrink: 0;
  a {
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;
    width: 60%;
    display: inline-block;
    img { width: 100%; }
  }
`
export const StyledInputContent = styled.div`
  margin-bottom: 15px;
`

export const StyledButton = styled.button`
  width: 100%;
  height: 35px;
  border: 0;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  line-height: 1.5;
  padding: 0 25px;
  font-size: 14px;
  border-radius: 4px;
  height: 36px;
  user-select: none;
  position: relative;
  color: #fff;
  background-color: #b3c738;
  border-color: #ffd923;
  transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
`

export const StyledSeparate = styled.div`
  padding-top: 40px;
  margin-top: 35px;
  border-top: 1px dashed #D8D8D8;
`

export const StyledLink =  styled(Link)`
  font-size: 12px;
  color: #797979;
  margin-bottom: 10px;
`