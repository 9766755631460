import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  margin: ${({ margin }) => margin};
`;

export const StyledButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ size }) => size};
`;
export const StyledFilterContainer = styled.div`
  display: flex;
  .filter-content {
    display: flex;
    width: 50%;
    &.left {
      select {
        &:first-child {
          margin: 0 5px 0 0px;
        }
        &:last-child {
          margin: 0 0px 0 5px;
        }
        width: calc(50% - 5px);
      }
    }
    &.right {
      select {
        margin: 0 0px 0 10px;
        width: calc(34% - 10px);
      }
    }
    select {
      padding: 4px 10px;
      height: 25px;
      cursor: text;
      text-align: left;
      font-size: 13px;
      line-height: 1.5;
      color: #595959;
      background-color: #fff;
      background-image: none;
      border: 1px solid #e9e9e9;
      outline: none;
      border-radius: 4px;
      margin-bottom: 15px;
      height: 36px;
      cursor: pointer;
    }
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    .filter-content {
      width: auto;
      margin: 10px 0px 0px 0px;
      &.right {
        select {
          &:first-child {
            margin: 0;
            width: 34%;
          }
        }
      }
      @media (max-width: 470px) {
        flex-direction: column;
        &.right,
        &.left {
          margin: 0;
          select {
            width: 100% !important;
            margin: 10px 0px 0px 0px !important;
          }
        }
      }
    }
  }
`;
export const StyledModalContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 200;
  overflow-x: scroll;
  p {
    margin: 0;
  }
  .form-container {
    width: 550px;
    @media (max-width: 552px) {
      width: 100%;
    }
  }
`;
export const StyledFormContent = styled.div`
  background: #fff;
  height: fit-content;
  .input-content {
    display: flex;
    flex-direction: column;
    select {
      width: 100%;
      padding: 4px 10px;
      height: 25px;
      cursor: text;
      text-align: left;
      font-size: 13px;
      line-height: 1.5;
      color: #595959;
      background-color: #fff;
      background-image: none;
      border: 1px solid #e9e9e9;
      outline: none;
      border-radius: 4px;
    }
    select {
      height: 34px;
    }
  }
`;
