import cookie from 'react-cookies';

export const setAuth = (auth) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
    cookie.save('auth', auth, {
      path: "/",
      expires: expires
    });
}

export const setToken = (token) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save('authToken', token, {
    path: "/",
    expires: expires
  });
}

export const setOnesignal = () => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save('intranet-onesignal', {
    path: "/",
    expires: expires
  });
}

export const getAuth = () => cookie.load('auth', true) !== undefined ? cookie.load('auth') : '';
export const getToken = () => cookie.load('authToken', true) !== undefined ? cookie.load('authToken') : '';
export const getOnesignal = () => cookie.load('intranet-onesignal', true) !== undefined ? cookie.load('intranet-onesignal') : '';
export const removeToken = () => cookie.remove("authToken", { path: "/" });
export const removeAuth = () => cookie.remove("auth", { path: "/" });
export const removeOnesignal = () => cookie.remove("intranet-onesignal", { path: "/" });