import React from "react";

import RatingCard from "./RatingCard";

//styles
import { StyledRatingContainer } from "./styles";

const Rating = ({ permitions }) => {
  const dateObj = new Date();
  const year = dateObj.getUTCFullYear();

  return (
    <StyledRatingContainer>
      <div className="rating-data-container">
        {permitions.length > 0 && (
          <>
            {permitions.map((item, index) => {
              return <RatingCard key={index} type={item} fullData={year} />;
            })}
          </>
        )}
      </div>
    </StyledRatingContainer>
  );
};

export default Rating;
