export const messages = {
    'REQUIRED_FIELD': 'Campo obligatorio.',
    'ERROR_SERVER_LOGIN': 'Datos incorrectos, intenta de nuevo.',
    'INCORRECT_FORMAT': 'Formato incorrecto.',
    'SEND_EMAIL_PASSWORD_SUCCESS': 'Te hemos enviado un email para que puedas modificar tu contraseña',
    'TOKEN_INVALID': 'Token invalido',
    'PASSWORD_DONT_MATCH': 'Las contraseñas no coinciden.',
    'RESET_PASSWORD_SUCCESS': 'La contraseña se ha cambiado correctamente.',
    'DISPATCHER_TEAM_UPDATE_SUCCESS': 'Equipo Actualizado correctamente.',
    'DISPATCHER_TEAM_UPDATE_ERROR': 'Error: No se ha podido actualizar el equipo, intentalo más tarde.',
    'DISPATCHER_TEAM_UPDATE_CHARGE_PRESSURE_SUCCESS': 'Carga de trabajo Actualizada correctamente.',
    'DISPATCHER_TEAM_UPDATE_CHARGE_PRESSURE_ERROR': 'Error: No se ha podido actualizar la carga de trabajo, intentalo más tarde.',
    'DISPATCHER_ALGORITMO_UPDATE_SUCCESS': 'Algoritmo Actualizado correctamente.',
    'DISPATCHER_ALGORITMO_UPDATE_ERROR': 'Error: No se ha podido actualizar el algoritmo, intentalo más tarde.',
    'DISPATCHER_ASSES_UPDATE_SUCCESS': 'Usuario Actualizado correctamente.',
    'DISPATCHER_ASSES_UPDATE_ERROR': 'Error: No se ha podido actualizar el usuario, intentalo más tarde.',
    'LEAD_ADD_SUCCESS': 'Candidato añadido correctamente',
    'LEAD_ADD_ERROR': 'Error: No se ha podido añadir el candidato, intentalo más tarde.'
}