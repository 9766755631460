import React, { useEffect, useState, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";

//libs
import { getToken } from "../../libs/cookies";
import { oneSignal } from "../../libs/helpers/methods";

//context
import { UseUser } from "../../hooks/context/user";
import { useNotification } from "../../hooks/context/notifaction";

// styled notification
import "react-toastify/dist/ReactToastify.css";

//components
import Header from "../header";
import Sidebar from "../sidebar";
import Loading from "../loading";
import AlertNotification from "../alertNotification";

//styles
import {
  StyledLayoutContainer,
  StyledBodyContainer,
  StyledHiddenSidebar,
  StyledMainContainer,
} from "./styles";

const windowWidth = () => {
  if (window.innerWidth <= 700) {
    return "phone";
  } else {
    return "desktop";
  }
};

function Layout(props) {
  const [menu, setMenu] = useState("desktop-menu-open");
  const [auth, setAuth] = useState(null);
  const { user, getMe, getMenu, checkToken } = UseUser();
  const { getNewsNotifications } = useNotification();
  const { loggedNow } = user;
  const token = getToken();

  // const renders = React.useRef(0)

  useEffect(() => {
    checkUser();
    getMe(token);
    getMenu(token);
    getNewsNotifications(token);
    // al inicio de la aplicacion
    if (windowWidth() === "phone") setMenu("phone-menu-close");

    const handleResize = () => {
      //listener
      if (windowWidth() === "phone") {
        setMenu("phone-menu-close");
      } else {
        setMenu("desktop-menu-open");
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const checkUser = () => {
    if (!loggedNow) {
      if (token) {
        checkToken(token)
          .then((res) => {
            setAuth(true);
            oneSignal();
          })
          .catch((err) => {
            setAuth(false);
          });
      } else {
        setAuth(false);
      }
    } else {
      oneSignal();
      setAuth(true);
    }
  };

  const actionMenu = useCallback((value) => {
    if (windowWidth() === "desktop") {
      setMenu(
        value === "desktop-menu-open"
          ? "desktop-menu-close"
          : "desktop-menu-open"
      );
    } else {
      setMenu(
        value === "phone-menu-open" ? "phone-menu-close" : "phone-menu-open"
      );
    }
  }, []);

  if (auth === null) {
    return <Loading />;
  } else if (auth) {
    return (
      <StyledLayoutContainer>
        <AlertNotification />
        <Header actionMenu={actionMenu} menu={menu} />
        <StyledBodyContainer>
          <Sidebar menu={menu} />
          <StyledHiddenSidebar className={menu} />
          <StyledMainContainer>{props.children}</StyledMainContainer>
        </StyledBodyContainer>
      </StyledLayoutContainer>
    );
  } else {
    return <Redirect to="/login" />;
  }
}

export default React.memo(withRouter(Layout));
